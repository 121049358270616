<template>
  <div class="content">
    <div class="top-nav">
      <span class="btn" @click="getHome">&lt;</span>
      <span>内容详情</span>
      <span></span>
    </div>
    <p class="top-tilte">[线上兼职]小红薯客服，300元/天，居家办公</p>
    <div>
      <div class="title">● 招募岗位</div>
      <p class="psd">小红书兼职</p>
    </div>
    <div>
      <div class="title">● 工作内容</div>
      <p class="psd">负责淘宝闲鱼拼多多店铺的整理，和客户购买产品的沟通</p>
    </div>
    <div>
      <div class="title">● 岗位要求</div>
      <p class="msg">
        <span>1.本职位线上居家办公即可</span>
        <span>2.工作内容是在小红书发布作品即可，公司提供素材，照发即可</span>
        <span>3.每天按照规定的时间规定的方法准时发布素材。没有创意要求</span>
      </p>
    </div>
    <div>
      <div class="title">● 福利待遇</div>
      <p class="psd">月薪6-8000</p>
    </div>
    <div>
      <div class="title">联系方式</div>
      <img src="@/assets/images/xhsbm.jpg" alt="" class="pic" />
    </div>
    <div>
      <div class="title">请扫码添加企业微信报名！</div>
      <p class="psd">
        【岗位仅限本科及以上学历报名，所有报名均免费，每日报名人数较多，报名后请勿删除，避免收不到录取信息】
      </p>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()
const getHome = () => {
  router.push('/')
}
</script>
<style lang="scss" scoped>
.content {
  font-size: 18px;
  .top-nav {
    display: flex;
    justify-content: space-between;
    padding: 10px 5px 20px 5px;
    .btn {
      padding-left: 20px;
      font-weight: lighter;
    }
  }
  .top-tilte {
    font-weight: bolder;
    padding: 10px;
    line-height: 30px;
  }
  .title {
    margin: 20px 5px;
    color: #b02418;
  }
  .psd {
    padding: 10px;
  }
  .works span {
    padding: 0 10px;
    display: block;
    line-height: 40px;
  }
  .pic {
    margin: 0 5vw;
    width: 90vw;
    height: 380px;
  }
  .msg span {
    padding: 0 10px;
    display: block;
    line-height: 30px;
  }
}
</style>
